export enum AidFrontendFeatureGate {
  // https://switcheroo-atlassian-com.jira.btpn.skyfencenet.com/ui/gates/747daf81-87f7-46dc-8ce3-c9e229c6c60f/key/should_fail_open_on_recaptcha_aid-frontend
  SHOULD_FAIL_OPEN_RECAPTCHA = 'should_fail_open_on_recaptcha_aid-frontend',
  // TODO remove after resolving HOT-113002
  // https://switcheroo-atlassian-com.jira.btpn.skyfencenet.com/ui/gates/b62923fa-9ad8-4aa4-9c00-f265ea08db0d/key/hot-113002_force_checkbox_recaptcha
  HOT_113002_FORCE_CHECKBOX = 'hot-113002_force_checkbox_recaptcha',
  // https://switcheroo-atlassian-com.jira.btpn.skyfencenet.com/ui/gates/c2e09b58-639a-4260-89de-4b390ac2b746/key/identity-aid-frontend-sms-enrollment-new-captcha
  // https://hello-atlassian-net.jira.btpn.skyfencenet.com/wiki/spaces/I/pages/4492639544/22-11-2025+Current+State+of+UniCAPTCHA+rollout
  SMS_ENROLLMENT_UNICAPTCHA = 'identity-aid-frontend-sms-enrollment-new-captcha',
  // https://switcheroo-atlassian-com.jira.btpn.skyfencenet.com/ui/gates/cab08915-6f2f-4672-9dc9-6abf170544aa/key/aid-frontend_use_new_navigate_hook
  USE_NEW_NAVIGATE_HOOK = 'aid-frontend_use_new_navigate_hook',
}
