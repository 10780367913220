import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import type { To } from '@remix-run/router';
import type { NavigateOptions } from 'react-router/dist/lib/context';
import { checkFeatureGate } from '../feature-flags/feature-gate';
import { AidFrontendFeatureGate } from '../../providers/FeatureGates';

const isToExternalHost = (to: string): boolean => {
  try {
    const url = new URL(to);
    return url.hostname !== window.location.hostname;
  } catch (e) {
    // "to" can also validly be a path without a hostname
    return false;
  }
};

// react-router breaks if it is provided with domain information (e.g. https://id-atlassian-com.jira.btpn.skyfencenet.com:443)
// so we need to remove it
const removeDomainInformation = (to: string): string => {
  let pathname: string;
  try {
    const url = new URL(to);
    pathname = url.pathname + url.search + url.hash;
  } catch (e) {
    // "to" can also validly be a path without a hostname
    pathname = to;
  }

  return pathname;
};

// this is needed because the parameters of Partial<Path> are a subset of URL, but if we
// pass a URL to react-router's navigate function, it may break.
const stringifyIfUrl = (to: number | To | URL): number | To => {
  if (to instanceof URL) {
    return to.toString();
  }
  return to;
};

/**
 * This hook is a wrapper around react-router's useNavigate that will use soft navigation if possible,
 * otherwise will fall back to a hard redirect using window.location.assign.
 */
export const useNavigateSoftOrHard = () => {
  const softNavigate = useNavigate();

  const navigate = useCallback<NavigateFunction>(
    (to: number | To | URL, options?: NavigateOptions) => {
      const destination = stringifyIfUrl(to);

      if (typeof destination === 'number') {
        return softNavigate(destination);
      }

      if (typeof destination !== 'string') {
        // destination is a Partial<Path>
        return softNavigate(destination, options);
      }

      if (options) {
        return softNavigate(destination, options);
      }

      if (isToExternalHost(destination)) {
        return window.location.assign(destination);
      }

      const pathname = removeDomainInformation(destination);
      const isValidSoftPath = validAppPaths.some(appPath => pathname.startsWith(appPath));
      const isForcedHardPath = forceHardPaths.some(hardPath => pathname.startsWith(hardPath));

      if (isValidSoftPath && !isForcedHardPath) {
        return softNavigate(pathname);
      }

      return window.location.assign(destination);
    },
    [softNavigate]
  );

  return checkFeatureGate(AidFrontendFeatureGate.USE_NEW_NAVIGATE_HOOK) ? navigate : softNavigate;
};

// temporary hook to wrap migration of `window.location.assign` to `useNavigateSoftOrHard`
// needed because when migrating from window.location.assign to our new navigate function,
// all calls need to be done using a string arg as this is the only common type between them.
export const useMaybeHardRedirect = (): ((url: string) => void) => {
  const newNavigate = useNavigateSoftOrHard();
  // window.location.assign breaks if we pass the callback directly
  const hardNavigate = useCallback((url: string) => window.location.assign(url), []);

  return checkFeatureGate(AidFrontendFeatureGate.USE_NEW_NAVIGATE_HOOK)
    ? newNavigate
    : hardNavigate;
};

// These paths must use hard navigation.
// Despite being on id.atlassian.com, they are not a registered path in the app.
// Usually, these paths are routed to id-authentication.
const forceHardPaths = [
  '/login/authorize',
  '/login/google',
  '/login/impersonate',
  '/login/initiate',
  '/login/login', // weird, but we have code that handles this in idproxy to route back to /login
  '/login/manage-session',
  '/login/saml',
];

// TODO [KIRBY-7828]: when we switch to using react-router to determine valid paths, consider how we want to
// TODO [KIRBY-7828]: handle the root level IDAC path, and the root-level wildcard path "*" (and other wildcards).
// https://hello.jira.atlassian.cloud/browse/KIRBY-7828
/**
 * contains all paths registered in App.tsx:
 * - removing wildcards from the path strings as we will use substring matching
 * - removing the root level "*" redirect (this means that root level IDAC will always be a hard redirect)
 */
const validAppPaths = [
  `/2step/enrollment/1-get-app`,
  `/2step/enrollment/2-configure-app`,
  `/2step/enrollment/2-configure-phone`,
  `/2step/enrollment/2-register-security-key`,
  `/2step/enrollment/3-connect-phone`,
  `/2step/enrollment/4-save-recovery-key`,
  `/2step/enrollment`,
  `/2step`,
  '/2step/recover/expired',
  '/2step/recover',
  '/login/resetpassword',
  '/login/continue-as',
  '/login/select-account',
  '/login/remove-account',
  '/login/cancel-account-deletion',
  '/login/deletion-request-confirmation',
  '/login/resetexpiredpassword',
  '/login/changepassword/', // wildcard route in App.tsx
  '/login/inactive',
  '/login/pending-deletion',
  '/login/mfa',
  '/login/consent',
  '/login/social/confirmation',
  '/login/', // wildcard route in App.tsx
  '/login',
  '/logout',
  '/mf',
  '/signup/migrate-confirmation',
  '/signup/welcome/sent',
  '/signup/welcome/', // wildcard route in App.tsx
  '/signup/invite/', // wildcard route in App.tsx
  '/signup/verify-email/otp',
  '/signup',
  '/verify-email/change-email',
  '/verify-email/sent',
  '/verify-email/', // wildcard route in App.tsx
  '/security/expiredlink',
  '/security/successfulreset',
  '/security/successfulverification',
  '/login/security-screen',
  '/social/recovery',
  '/error',
  '/step-up/start',
  '/step-up/sso',
];
