// https://w3c.github.io/resource-timing/#sec-performanceresourcetiming
var AJAX_INITIATOR_TYPES = ['fetch', 'xmlhttprequest', 'beacon', 'other'];
var DEFAULT_REQUEST_BLACKLIST = [
    // development purposes
    'localhost',
    // anvil (stargate)
    'atlassian-com.jira.btpn.skyfencenet.com/metal/ingest',
    // new relic
    'bam.nr',
    // webstatsd ingest
    'webstatsd',
    // GASv3 (stargate)
    'gateway/api/gasv3',
];
export { AJAX_INITIATOR_TYPES, DEFAULT_REQUEST_BLACKLIST };
